import {GetStoresZonesComponent_CachedQuery, GetStoresZonesComponent_CachedQueryVariables} from '@data/__generated__/types.main'
import getClient from '@providers/apollo/getClient'
import gql from 'graphql-tag'

export const deliveryZonesQuery = gql`
  query getStoresZonesComponent_cached($websiteId: ID) {
    stores(websiteId: $websiteId) {
      items {
        _id
        name
        acceptDelivery
        zones {
          _id
          deliveryLimits
        }
        address {
          placeId
          location
        }
      }
    }
  }
`

export default async function getStoreZones(websiteId: string) {
  const {data} = await getClient().query<GetStoresZonesComponent_CachedQuery, GetStoresZonesComponent_CachedQueryVariables>({
    query: deliveryZonesQuery,
    variables: {
      websiteId
    }
  })

  return data.stores
}
