import {useSuspenseQuery} from '@apollo/client'
import Button from '@components/Button'
import {
  GetStoresZonesComponent_CachedQuery,
  GetStoresZonesComponent_CachedQueryVariables,
} from '@data/__generated__/types.main'
import {deliveryZonesQuery} from '@data/queries/components/deliveryZone.main'
import getPathname from '@helpers/router/getPathname'
import useWebsiteId from '@hooks/useWebsiteId'
import Container from '@packages/justo-parts/lib/components/Container'
import flattenDeep from 'lodash/flattenDeep'
import {useRouter} from 'next/router'
import {useState} from 'react'

import Leaflet from './Leaflet'

import styles from './styles.module.css'

export interface DeliveryZoneProps {
  stores: any
  color: string
  icon: any
}

export default function DeliveryZone(props: DeliveryZoneProps) {
  const router = useRouter()
  const [show, setShow] = useState(!['/', '/pedir', '/checkout'].includes(getPathname(router)))
  const websiteId = useWebsiteId()

  const {data} = useSuspenseQuery<
    GetStoresZonesComponent_CachedQuery,
    GetStoresZonesComponent_CachedQueryVariables
  >(deliveryZonesQuery, {
    variables: {websiteId},
    fetchPolicy: 'cache-first',
  })

  const {stores} = data

  const getStores = () => {
    if (!stores) return []
    return stores.items
      .filter(store => store.acceptDelivery)
      .filter(store => store.address?.location)
  }
  const getPoints = (): {lat: number; lng: number}[] => {
    return flattenDeep(getStores().map(store => store.zones.map(zone => zone.deliveryLimits)))
  }

  const getCenter = () => {
    const count = getStores().length
    return getStores().reduce(
      (last, store) => {
        const lat = store.address.location.lat / count
        const lng = store.address.location.lng / count
        return {lat: last.lat + lat, lng: last.lng + lng}
      },
      {lat: 0, lng: 0},
    )
  }

  if (!stores) return null
  if (!getStores().length) return null

  const mapProps = {
    iconURL: props.icon,
    color: props.color,
    center: getCenter(),
    getStores,
    getPoints,
  }

  return (
    <div className={styles.container}>
      <Container>
        <div className={styles.title}>Cobertura del despacho</div>
        {show ? (
          /** @ts-expect-error temporary fix */
          <Leaflet {...mapProps} />
        ) : (
          <Button onClick={() => setShow(true)}>Mostrar mapa</Button>
        )}
      </Container>
    </div>
  )
}
